<template>
  <div>
    <div class="image-container color-primary">
      <b-img
        class="color-primary styling-image placeholder-image"
        center
        thumbnail
        fluid
        :src="url"
      ></b-img>
    </div>
    <b-input-group>
      <b-input-group-prepend is-text>
        <b-icon icon="image-fill"></b-icon>
      </b-input-group-prepend>
      <b-form-file
        :name="title"
        @change="onFileChange($event)"
        :placeholder="url"
        accept=".jpg, .jpeg, .png, .gif, .ico"
        v-model="url"
      />
      <div v-if="errors.has(title)" class="alert-danger">
        {{ errors.first(title) }}
      </div>
    </b-input-group>
  </div>
</template>
<script>
import axios from "@/plugins/axios.plugin";

export default {
  props: ["url", "title"],
  methods: {
    async onFileChange(event) {
      await this.uploadImage(event.target.files[0]);
    },
    async uploadImage(file) {
      let formData = new FormData();
      formData.append("file", file);
      const response = await axios.post("/files/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
        },
        responseType: "json",
      });
      this.$emit('updateImage', process.env.VUE_APP_FILE_URL + response.data.url);
    },
  },
};
</script>
<style lang="scss">
  .placeholder-image {
    max-height: 200px;
  }
</style>